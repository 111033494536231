/**
 * This file is its own entry point to set up Sentry exception handling ahead of
 * loading the main bundle. We do this so that any errors from loading and
 * evaluating the main bundle can get sent to Sentry.
 */
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import React from 'react';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';

/**
 * Set to true to debug Sentry things locally.
 *
 * We don’t enable Sentry by default in development because we don’t want errors
 * from bugs when writing code to clog up the exceptions list.
 */
const DEBUG_SENTRY = false;
const PRODUCTION_TRACES_SAMPLE_RATE = 0.1;

// This will be "production" or "staging" or "development". Set via our GitHub
// Actions and passed through in the Webpack config.
const deployEnv = process.env['DEPLOY_ENV'] ?? 'development';

// The release name will be injected automatically by SentryCliPlugin in a way
// that the Sentry library will see it, which is why we don’t specify it in the
// options here.
const SENTRY_OPTIONS = {
  debug: DEBUG_SENTRY,
  enabled:
    deployEnv === 'staging' || deployEnv === 'production' || DEBUG_SENTRY,

  dsn: process.env['SENTRY_URL'] ?? '',
  environment: deployEnv,

  integrations: [
    new BrowserTracing({
      // Causes Sentry to send headers to our backend that will allow it to trace
      // through our entire system.
      tracePropagationTargets: [process.env['API_URL'] ?? 'localhost'],

      routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes
      ),
    }),
  ],

  tracesSampleRate:
    deployEnv !== 'production' || DEBUG_SENTRY
      ? 1.0
      : PRODUCTION_TRACES_SAMPLE_RATE,
};

Sentry.init(SENTRY_OPTIONS);
